import { FieldTypes } from '../../entities/types';
import { User } from '../../types';
import { booleanFormatFn } from '../../utils/utils';
import { WebEntity } from './types';

const UsuarioEntity: WebEntity<User> = {
  name: 'usuario',
  endpoint: 'usuarioTransportista/usuarios',
  referenceColumn: '_id',
  customButtonNames: {
    addButtonName: 'Agregar usuario'
  },
  customDataActions: {
    addModalTitle: 'Agregar usuario',
    editModalTitle: 'Editar usuario'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text',
      enableHiding: false,
      visibleInShowHideMenu: false
    },
    {
      header: 'Nombre',
      accessorKey: 'nombre',
      columnType: 'text'
    },
    {
      header: 'Nombre usuario',
      accessorKey: 'nombreUsuario',
      columnType: 'text'
    },
    {
      header: 'Email',
      accessorKey: 'emailUsuario',
      columnType: 'text'
    },
    {
      header: 'Tipo usuario',
      accessorKey: 'nombreTipoUsuario',
      columnType: 'text'
    },
    {
      header: 'Activo',
      accessorKey: 'activo',
      columnType: 'boolean',
      Cell: ({ row }) => booleanFormatFn(row.original.activo)
    }
  ],

  fields: [
    {
      name: 'Nombre',
      selector: 'nombre',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Nombre de Usuario',
      selector: 'nombreUsuario',
      type: FieldTypes.Text,
      required: true,
      formatStr: (value) => (value || '').toLowerCase()
    },
    {
      name: 'Email',
      selector: 'emailUsuario',
      type: FieldTypes.Email,
      required: true,
      formatStr: (value) => (value || '').toLowerCase()
    },
    {
      name: 'Clave',
      selector: 'claveUsuario',
      type: FieldTypes.Password,
      required: true
    },
    {
      name: 'Tipo',
      selector: 'idTipoUsuario',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'tipoUsuario',
        endpoint: 'usuarioTransportista/tipoUsuarios',
        select: '_id',
        show: 'nombreTipoUsuario'
      }
    }
  ],

  editableFields: [
    {
      name: 'Nombre',
      selector: 'nombre',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Nombre de Usuario',
      selector: 'nombreUsuario',
      type: FieldTypes.Text,
      required: true,
      formatStr: (value) => (value || '').toLowerCase()
    },
    {
      name: 'Email',
      selector: 'emailUsuario',
      type: FieldTypes.Email,
      required: true,
      formatStr: (value) => (value || '').toLowerCase()
    },
    {
      name: 'Clave',
      selector: 'claveUsuario',
      type: FieldTypes.Password
    },
    {
      name: 'Tipo',
      selector: 'idTipoUsuario',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'tipoUsuario',
        endpoint: 'usuarioTransportista/tipoUsuarios',
        select: '_id',
        show: 'nombreTipoUsuario'
      }
    }
  ]
};

export default UsuarioEntity;
