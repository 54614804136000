import { Documento, TipoUsuarioValues } from '../types';
import { FieldTypes, WebEntity, UpdateDependencyFunc } from './types';
import { dateFormatFn } from '../utils/utils';

const updateDependencyFecha =
  (
    fieldName: string,
    fieldToUpdate:
      | 'fechaDocumento'
      | 'fechaDocumentoFin'
      | 'fechaCargaDocumento'
      | 'fechaCargaDocumentoFin'
  ): UpdateDependencyFunc =>
  async (fieldValue, getField, setLoadings, _, updateFieldValue, getFieldValue): Promise<void> => {
    try {
      const field = getField(fieldToUpdate);
      setLoadings([field], true);

      const fieldValueToUpdate = getFieldValue(fieldToUpdate);

      const fieldValueDateFormat = new Date(fieldValue);
      const fieldValueToUpdateDateFormat = new Date(fieldValueToUpdate);

      const checkIfIsStart = () =>
        fieldName === 'fechaDocumento' || fieldName === 'fechaCargaDocumento';
      const checkIfIsEnd = () =>
        fieldName === 'fechaDocumentoFin' || fieldName === 'fechaCargaDocumentoFin';
      const checkIfIsMajor = () => fieldValueDateFormat > fieldValueToUpdateDateFormat;
      const checkIfIsMinus = () => fieldValueDateFormat < fieldValueToUpdateDateFormat;

      let newValueToUpdate = fieldValueToUpdate;

      if (fieldValue && fieldValueToUpdate) {
        if ((checkIfIsEnd() && checkIfIsMinus()) || (!checkIfIsEnd() && checkIfIsMajor())) {
          newValueToUpdate = fieldValue;
        }
      } else if (
        (!fieldValue && fieldValueToUpdate && checkIfIsStart()) ||
        (fieldValue && !fieldValueToUpdate && checkIfIsEnd())
      ) {
        newValueToUpdate = fieldValue;
      }

      updateFieldValue(fieldToUpdate, newValueToUpdate);
      setLoadings([field], false);
    } catch (error) {
      console.log(error);
    }
  };

const DocumentoCriticidadEntity: WebEntity<Documento> = {
  name: 'documentoCriticidad',
  endpoint: 'documentosCriticidad',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      id: '_id',
      selector: (row) => row._id,
      columnType: 'text',
      omitExport: true,
      omit: true
    },
    {
      name: 'Documento ID',
      id: 'idDocumento',
      selector: (row) => row.idDocumento,
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Usuario',
      id: 'nombreUsuario',
      selector: (row) => row.nombreUsuario,
      columnType: 'text'
    },
    {
      name: 'Estado Documento',
      id: 'descripcionEstadoDocumento',
      selector: (row) => row.descripcionEstadoDocumento,
      columnType: 'text',
      wrap: true,
      conditionalCellStyles: [
        {
          when: (row) => row.idEstadoDocumento === 3,
          style: {
            color: 'var(--ccu-verde-claro, #64A70B)',
            fontFamily: 'Roboto',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal'
          }
        },
        {
          when: (row) => row.idEstadoDocumento === 1,
          style: {
            color: 'var(--ccu-amarillo, #FFB81C)',
            fontFamily: 'Roboto',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal'
          }
        },
        {
          when: (row) => row.idEstadoDocumento === 4,
          style: {
            color: 'var(--ccu-amarillo, red)',
            fontFamily: 'Roboto',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal'
          }
        }
      ]
    },
    {
      name: 'Fecha Recuento',
      id: 'fechaDocumento',
      selector: (row) => row.fechaDocumento,
      columnType: 'dateUTC',
      format: ({ fechaDocumento: d }) => dateFormatFn(d, 'DD/MM/YYYY', true),
      wrap: true,
      sortable: true
    },
    {
      name: 'Fecha Carga',
      id: 'fechaCargaDocumento',
      selector: (row) => row.fechaCargaDocumento,
      columnType: 'dateTime',
      format: ({ fechaCargaDocumento: d }) => dateFormatFn(d),
      wrap: true,
      sortable: true
    },
    {
      name: 'Zona',
      id: 'nombreCentro',
      selector: (row) => row.nombreCentro,
      columnType: 'text',
      wrap: true
    }
  ],

  editableFields: [
    {
      name: 'Estado',
      selector: 'idEstadoDocumento',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'estadoDocumento',
        endpoint: 'estadoDocumento',
        select: '_id',
        show: 'descripcionEstadoDocumento',
        filter: {
          activo: true
        }
      }
    },
    {
      name: 'Fecha Recuento',
      selector: 'fechaDocumento',
      type: FieldTypes.Date,
      timezone: 'UTC',
      onlyAdmin: true
    }
  ],

  filterFields: [
    {
      name: 'Usuario',
      selector: 'idUsuario',
      type: FieldTypes.Reference,
      required: false,
      reference: {
        name: 'usuario',
        endpoint: 'usuarios',
        select: '_id',
        show: 'nombreUsuario',
        filter: {
          activo: true,
          tipoUsuarioValues: [
            TipoUsuarioValues.Admin,
            TipoUsuarioValues.Operador,
            TipoUsuarioValues.Visualizador
          ]
        }
      },
      onlyAdmin: true
    },
    {
      name: 'Estado Documento',
      selector: 'idEstadoDocumento',
      type: FieldTypes.Reference,
      required: false,
      reference: {
        name: 'estadoDocumento',
        endpoint: 'estadoDocumento',
        select: '_id',
        show: 'descripcionEstadoDocumento'
      }
    },
    {
      name: 'Fecha Recuento',
      selector: 'fechaDocumento',
      type: FieldTypes.Date,
      timezone: 'America/Santiago',
      groupNumber: 1,
      required: false,
      updateDependency: updateDependencyFecha('fechaDocumento', 'fechaDocumentoFin')
    },
    {
      name: '-',
      selector: 'fechaDocumentoFin',
      type: FieldTypes.Date,
      timezone: 'America/Santiago',
      groupNumber: 1,
      required: false,
      updateDependency: updateDependencyFecha('fechaDocumentoFin', 'fechaDocumento')
    },
    {
      name: 'Fecha Carga',
      selector: 'fechaCargaDocumento',
      type: FieldTypes.Date,
      timezone: 'America/Santiago',
      groupNumber: 2,
      required: false,
      updateDependency: updateDependencyFecha('fechaCargaDocumento', 'fechaCargaDocumentoFin')
    },
    {
      name: '-',
      selector: 'fechaCargaDocumentoFin',
      type: FieldTypes.Date,
      timezone: 'America/Santiago',
      groupNumber: 2,
      required: false,
      updateDependency: updateDependencyFecha('fechaCargaDocumentoFin', 'fechaCargaDocumento')
    }
  ]
};

export default DocumentoCriticidadEntity;
